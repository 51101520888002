import {useLocalStorage} from "@vueuse/core";
import {router} from "@inertiajs/vue3";
import {DsRoute} from "~/helper/DsRoute";

export const sidebar_open = useLocalStorage('sidebar_open', false);
export function goToRoute(route: string, route_params: any = {},target_blank = false){
    if(target_blank){
        window.open(DsRoute.route(route, route_params), '_blank');
        return;
    }

    sidebar_open.value = false;
    router.get(DsRoute.route(route, route_params))
}
